<template>
  <header class="header">
    <div class="first-line" @click="logoClick">
      <img src="/assets/logo-text-orange.png" alt="Menu Icon" class="logo-text" />
    </div>
  </header>
</template>

<script>
import { useDisplay } from 'vuetify';

export default {
  data() {
    return {
      errorMessage: "",
      display: {}
    };
  },
  async mounted() {
    this.display = useDisplay();
  },
  methods: {
    logoClick() {
      this.$router.push('/home');
    },
  },
};
</script>

<style scoped>
.header {
  height: 7vh;
  width: 100% - 20px;
  flex-direction: column;
  background-color: #FBFCFC;
  color: white;
  padding-left: 12px;
  max-width: 500px;
  margin: 0 auto;
}

.first-line {
  width: 100%;
  text-align: left;
  padding-top: 10px;
}

.logo-text {
  height: 40px;
  cursor: pointer;
}
</style>
